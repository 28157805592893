import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getVatReconciliationColumns() {

  const colArr = [
    {
      label: "Account ID",
      value: true,
      columnId: "accountId",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Opening Bal.",
      value: true,
      columnId: "openingBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Total Debit",
      value: true,
      columnId: "totalDebit",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Total Credit",
      value: true,
      columnId: "totalCredit",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Net Activity",
      value: true,
      columnId: "netActivity",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "End Balance",
      value: true,
      columnId: "endBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
