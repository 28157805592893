import { getRevenueRecognitionDetailRows, getRevenueRecognitionDetailColumns } from "./revenue-recognition-detail-statement";
import { getRevenueRecognitionDetail } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const revenueRecognitionDetailFilters = {
  CompanyName: "*",
  CustomerNumber: "*",
  CustomerName: "*",
  ComponentName: "*",
  ComponentNumber: "*",
  ContractNumber: "*"
};

const revenueRecognitionDetailFilterOptions = [
  { key: "companyName", apiKey: "CompanyName" },
  { key: "customerNumber", apiKey: "CustomerNumber" },
  { key: "customerName", apiKey: "CustomerName" },
  { key: "componentName", apiKey: "ComponentName" },
  { key: "componentNumber", apiKey: "ComponentNumber" }
];

export default function ReportRevenueRecognitionDetail({ activeCompany }) {
  return (
    <DynamicReport
      reportName="revenue-recognition-details"
      activeCompany={activeCompany}
      columnsDataProvider={getRevenueRecognitionDetailColumns}
      rowsDataProvider={getRevenueRecognitionDetailRows}
      fetchData={getRevenueRecognitionDetail}
      exportUrl="reports/export-query-report/revenue-recognition-details"
      orderBy="CompanyName"
      filtersInitialState={revenueRecognitionDetailFilters}
      filterOptions={revenueRecognitionDetailFilterOptions}
    />
  );
}
